import React from 'react';
// import PropTypes from 'prop-types';
import Layout from 'components/layout';
// import HeaderJumbotron from 'components/headerJumbotron';
// import IconSection from 'components/iconSection';
// import ZetaSection from 'components/zetaSection';
// import RoomsBox from 'components/roomsBox';
// import MapsSection from 'components/mapsSection';
// import Footer from 'components/footer';
import { Container, Row, Col, Button } from 'reactstrap';
import { graphql } from 'gatsby';
import RoomCard from 'components/generic/Cards/RoomCard';

import RoomBox from 'components/roomBox/roomBox';
import SEO from 'components/seo/index';
import GenericTitleTextCTA from 'components/generic/TitleTextCTA';

const jsonLD = {
  "@context": "http://schema.org",
  "@type": "Hotel",
  "name": "Hotel Grace Galaxy - Bandra West",
  "description": `Hotel Grace Galaxy is the best hotel in bandra west in it's class, known for it's prime location and friendly staff`,
  "address": {
    "@type": "PostalAddress",
    "addressCountry": "IN",
    "addressLocality": "Bandra",
    "addressRegion": "Mumbai",
    "postalCode": "400050",
    "streetAddress": "SV Road, Near Shoppers Stop, Bandra West"
  },
  "image": "https://hotelgracegalaxy.com/premium-hotel-room-bandra-mumbai.jpg",
  "telephone": "+91 77-188-00-522",
  "starRating": {
    "@type": "Rating",
    "ratingValue": "4"
  },
  "priceRange": "Rs.2500 - Rs.7000",
  "checkinTime": "12:30:00",
  "checkoutTime": "11:30:00"
}
const basicSEO = {
  title: "Hotel booking in Mumbai - Top hotels in mumbai at affordable price - hotels near beach - convenient location",
  description: "Book a room in bandra west now. top hotels in it's class, known for prime location, clean rooms and friendly staff. Near juhu beach, bandra kurla complex(BKC), carter road, pali hill",
  canonical: "https://hotelgracegalaxy.com/book"
}
const Index = ({ location }) => (
  <Layout>
    <SEO basicSEO={basicSEO} jsonLD={jsonLD} />
    {/* <HeaderJumbotron headerText="Comfortable stay, at the heart of Mumbai" /> */}
    <Container style={{ marginBottom: "100px" }}>
      {location.state != null && (
        <RoomCard />
      )}
      <RoomBox />
      {location.state == null && <RoomCard />}
      <GenericTitleTextCTA
        title="Need help?"
        text="Call us at +91 9538740296 for assistance, bulk booking and other details"
        callToActionText="Know more"
        callToActionUrl="/about" />
    </Container>
    {/* <Footer /> */}
  </Layout>
);

// Index.propTypes = {
//   data: PropTypes.object.isRequired,
// };

export default Index;

export const query = graphql`
  query {
    royal: file(
      relativePath: { eq: "images/gallery/royal-hotel-room-bandra-mumbai.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    superior: file(
      relativePath: {
        eq: "images/gallery/premium-hotel-room-bandra-mumbai.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bwsl: file(
      relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concept: file(relativePath: { eq: "images/gallery/concept.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    file(relativePath: { eq: "images/gallery/bandra-worli-sea-link.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
