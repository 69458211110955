import React from 'react';
import './cards.css';
import { Row, Col, Button, CustomInput } from 'reactstrap';
import Img from 'gatsby-image';
import StickyBox from 'react-sticky-box';
import Pluralize from 'react-pluralize';
import { StaticQuery, graphql } from 'gatsby';
import {
    // faWifi,
    faTrashAlt,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
// library.add(fab, faCheckSquare, faCoffee)
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import BookingInputBox from 'components/bookingInputBox';
// import {
//     DateRangePicker,
//     SingleDatePicker
// } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import axios from 'axios';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
// import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
// import {
//     BrowserView,
//     MobileView
// } from "react-device-detect";
import Spinner from 'react-svg-spinner';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import Analytics from 'analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const API = {
    processPayment:
        'https://gracegalaxyinstamojopaymentprocessor.negati-ve.now.sh/api/processPayment',
    // 'http://localhost:3000/api/processPayment',
    postPayment:
        'https://gracegalaxyinstamojopaymentprocessor.negati-ve.now.sh/api/postPayment',
    // 'http://localhost:3000/api/postPayment',
    djuboAvailability:
        'https://djubo-client.negati-ve.now.sh/api/getAvailability',
};
var querystring = require('querystring');

/* Initialize analytics */
const analytics = Analytics({
    app: 'HGGBook',
    version: 100,
    plugins: [
        googleAnalyticsPlugin({
            trackingId: 'UA-60584820-9',
        })
    ]
})

class RoomCard extends React.Component {
    constructor(props) {
        super(props);
        // console.log(this.props.checkout,'asd')
        this.state = {
            available: {
                compact: 5,
                deluxe: 5,
                superior: 5,
                royal: 5,
                twin: 5,
                triple: 5,
            },
            rate: {
                compact: 2200,
                deluxe: 2800,
                superior: 3500,
                royal: 3800,
                twin: 3800,
                triple: 3800,
                breakfast: 300,
                pickup: 500,
                drop: 500,
            },

        };
    }

    componentDidMount = () => {
        // console.log(this.state)
        /* Track a page view */
        analytics.page()
    };

    toggle = () => {
        this.setState({});
    };

    render() {
        return (
            <StaticQuery
                query={graphql`
          query {
            royal: file(
              relativePath: {
                eq: "images/gallery/royal-hotel-room-bandra-mumbai.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            superior: file(
              relativePath: {
                eq: "images/gallery/superior.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            budget: file(
              relativePath: {
                eq: "images/gallery/compact.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            deluxe: file(
              relativePath: {
                eq: "images/gallery/hotel-room-for-couples-in-bandra.JPG"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            twin: file(
              relativePath: {
                eq: "images/gallery/twin.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
             triple: file(
              relativePath: {
                eq: "images/gallery/triple.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
                render={data => (

                    <div>

                        {/* Display box */}
                        <Row
                            style={{
                                margin: '0px auto',
                                padding: '2rem 1rem',
                                boxShadow: '0 .225rem .75rem 0 rgba(0,0,0,.1)',
                                width: '100%',
                            }}
                        >
                            <Col lg={{ size: 4 }} md={{ size: 4 }} xs={{ size: 12 }}>
                                <Img
                                    style={{ borderRadius: '4px' }}
                                    fluid={data.budget.childImageSharp.fluid}
                                ></Img>
                            </Col>
                            <Col lg={{ size: 8 }} xs={{ size: 12 }}>
                                <h2 style={{ fontFamily: 'AllerLight' }}>Classic Compact</h2>{' '}
                                <span style={{ color: 'red' }}>
                                    {this.state.available['compact'] ? (
                                        <p>
                                            {' '}
                        Only {this.state.available['compact']} rooms available
                                        </p>
                                    ) : (
                                            <p>last room just sold out!</p>
                                        )}
                                </span>
                                <p>Pocket friendly cozy rooms </p>
                                <p>
                                    Max allowed guests:
                    <FontAwesomeIcon
                                        icon={faUser}
                                        // onClick={()=>this.addToCart(room)}
                                        style={{
                                            fontSize: '16px',
                                            color: '#EF8921',
                                            margin: '1px',
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        // onClick={()=>this.addToCart(room)}
                                        style={{
                                            fontSize: '16px',
                                            color: '#EF8921',
                                            margin: '1px',
                                        }}
                                    />
                                </p>
                                <Button
                                    style={{ marginRight: '10px', float: 'right' }}
                                    onClick={() => this.addToCart('compact')}
                                    className="roomGuestSelectorButtonStyle"
                                >
                                    {this.state.loading ? <Spinner /> : ''}
                                    {this.state.available['compact']
                                        ? 'Add to your reservation'
                                        : 'Sold out'}
                                </Button>
                            </Col>
                        </Row>
                        {/* End of Display box */}


                    </div>

                )}
            />
        );
    }
}
export default RoomCard;
